.mobile {
  display: none;
}
.mobile i {
  color: #fff;
}
@media only screen and (max-width: 426px) {
  .error-cover {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: -webkit-center;
  }
  .nav-bar h5 {
    font-size: 5vw;
    color: dodgerblue;
  }
  .carousel-div {
    position: relative;
    width: 100vw;
  }
  .carousel-div img {
    width: 100vw;
  }
  #bar{
    color: dodgerblue;
  }
  #nav-bar-links h4{
    font-size: 18px;
    color: #fff;
  }
  #nav-bar-links  a{
    font-size: 16px;
    color: #fff ;
    text-decoration: underline;
  }
  #nav-bar-links a span{
    font-size: 16px;
    color: rgb(201, 149, 29);
  }
  #nav-bar-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 40px;
    right: -60vw;
    width: 60vw;
    bottom: 0;
    background: #154c79;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 15%);
    transition: 0.3s ease-in-out;
    z-index: 10;
    opacity: 0.9;
    padding: 16px;
  }
  #nav-bar-links.active {
    right: 0;
  }
  .nav-bar img {
    width: 20%;
  }
  #bar {
    font-size: 6vw;
  }
  .mobile {
    display: block;
  }
  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 0px 20px;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 15%);
    height: 40px;
    width: 100vw;
  }
  .slick-slide .sli-img {
    width: 80vw;
    height: 38vw;
    margin: 0px 30px 0px 0px !important;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 15%);
  }
  .mobile i {
    font-size: 24px;
    cursor: pointer;
  }
  #nav-bar-links li {
    margin-bottom: 25px;
  }
  .container-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.5);
    padding: 0px;
    width: 90%;
  }
  .illustration {
    position: relative;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(1, 41, 112),
      rgba(37, 118, 184, 0.75)
    );
  }
  .illus-img img {
    display: none;
  }
  .illust-img img {
    display: none;
  }
  .illust-text h4 {
    padding-left: 10px;
    padding-top: 10px;
    color: #fff;
    font-size: 5vw;
  }
  .illust-text h4 span {
    color: rgb(201, 149, 29);
  }
  .container-wrapper {
    background: #fff;
    background-size: cover;
    background-position: center;
    padding: 20px 0px;
  }

  i.email {
    right: 20px;
    top: 13px;
    color: rgb(1, 49, 89, 0.5);
  }

  input {
    height: 40px;
    text-align: center;
    background: none;
    border: none;
    color: #013159;
    font-size: 12px;
  }

  input.email {
    border: 2px solid #013159;
    border-radius: 50px;
  }

  i.password {
    left: 20px;
    top: 13px;
    color: rgb(1, 49, 89, 0.5);
  }

  input.password {
    border: 2px solid #013159;
    border-radius: 50px;
  }

  button.submit {
    font-size: 12px;
    color: #013159;
    background: none;
    border: 2px solid #013159;
    border-radius: 20px;
    padding: 5px 0px;
    width: 100px;
  }
  button.submit:hover {
    color: white;
    background: #013159;
  }
  input[type="checkbox"] {
    border: 2px solid #013159;
    background: none;
  }

  label {
    color: #013159;
    font-size: 12px;
  }

  button.action {
    font-size: 12px;
    color: #013159;
    background: none;
    border: 2px solid #013159;
    border-radius: 20px;
    padding: 5px 0px;
  }
  button.action:hover {
    color: white;
    background: #013159;
  }
  .enroll-font {
    font-size: 3vw;
  }

  h1 {
    color: #033ad2;
  }
  .bottom-font {
    font-size: 4vw;
  }
  ::-webkit-input-placeholder {
    color: rgb(1, 49, 89, 0.5);
  }

  input:focus {
    outline: none;
  }
  .pad-left {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .img-backbo {
    background: url("../../../../public/assets/images/backblue.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    position: absolute;
  }
  .table-height2 {
    margin: 5% auto;
    width: 95%;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.15);
  }
  .table-height4 {
    /* margin: 20% auto; */
    width: 100%;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.15);
    border-radius: 10%;
  }
  .advert-margin {
    padding: 0px 0px;
  }
  .login-img {
    width: 40vw;
  }
  .offer-cover h5{
    font-size: 4vw;
    font-weight: 600;
    text-decoration: underline;
  }
  .offer-body h4{
    font-weight: 300 !important;
    font-size: 3px;
  }
  .offer-body h5{
    font-weight: 600 !important;
    font-size: 3vw;
  }
  .verify-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat !important;
  }
  .table-height3 {
    width: 100%;
    overflow-x: scroll;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.15);
  }
  .table-height {
    margin-top: 5%;
    width: 100%;
    background: transparent;
    padding: 0 !important;
  }
  .enroll-box {
    width: 30vw;
    height: 5vw;
    border: 1px solid #000;
  }
  .line {
    width: 70px;
    height: 1px;
    background: #000;
  }
  .line1 {
    margin-top: 10px;
    width: 70px;
    height: 1px;
    background: #000 !important;
  }
  .line3 {
    width: 25vw;
    height: 1px;
    background: #000;
  }
  .line4 {
    width: 55vw;
    height: 1px;
    background: #000;
  }
  .marg-top {
    margin-top: 50px;
  }
  .btn-mobile {
    font-size: 3vw;
  }
  .formtype h4 {
    font-size: 16px;
  }
  .pad-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .img-cover {
    margin: 20px auto;
    width: 80%;
    height: 160px;
    border: 1px solid #033ad2;
    overflow: hidden;
  }
  .main-cover {
    width: 90%;
    margin: 20px auto;
  }
  .img-cover img {
    width: 100%;
    height: 100%;
  }
  #pic-upload {
    opacity: 0;
    z-index: -1;
  }
  .head-cover {
    text-transform: uppercase;
    font-size: 4vw;
  }
  .admiss {
    border: 1px solid #000;
    width: 40vw;
    margin: 10px auto;
    padding: 5px 0px;
    font-size: 4vw;
  }
  .img-position {
    width: 20vw;
    height: 20vw;
    margin: 1% auto;
  }
  .wrapper1 {
    width: 20vw;
    height: 20vw;
    border: 1px solid #000;
    overflow: hidden;
  }
  .wrapper1 img {
    width: 20vw;
    height: 20vw;
  }
  .body-content h4 {
    font-size: 3vw;
    font-weight: 700;
  }
  .body-content h4 span {
    font-weight: 100;
  }
  .body-content h3 {
    font-size: 4vw;
    font-weight: 600;
    text-align: -webkit-center;
  }
  .div-content {
    margin-top: 10px;
    width: 100%;
    display: flex;
  }
  .div-content-1 {
    width: 66.66%;
  }
  .div-content-2 {
    width: 33.33%;
  }
  .div-content-cover {
    padding: 10px;
    border: 1px solid #000;
  }
  .div-content-fit {
    width: 50%;
  }
  .div-content-fit span{
    display: block;
    font-size: 3vw;
    color: #000;
    font-weight: 500;
  }
  .div-content-fit p{
    font-size: 3vw;
    color: #000;
    font-weight: 500;
  }
  .div-content-fit1 {
    width: 100%;
  }
  .div-content-fit1 span{
    display: block;
    font-size: 3vw;
    color: #000;
    font-weight: 500;
  }
  .div-content-fit1 p{
    font-size: 3vw;
    color: #000;
    font-weight: 500;
  }
  .space-line{
    width: 100%;
    height: 1px;
    background: #000000;
  }
  .d-width{
    width: fit-content;
  }
  .stu_id h1{
    font-size: 5vw;
  }
}

.bb {
  padding: 0 !important;
  margin: 0 !important;
}
body {
  margin: 0;
  padding: 0;
}
.card-h {
  min-height: 500px;
}
.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    90deg,
    hsla(212, 100%, 69%, 1) 20%,
    hsla(217, 100%, 50%, 1) 90%
  );
  padding: 20px 60px;
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 5%);
  transition: 0.3s ease-in-out;
  width: 100vw;
}
.nav-bar.active {
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 65%);
  background: linear-gradient(
    90deg,
    hsla(212, 100%, 69%, 1) 20%,
    hsla(217, 100%, 50%, 1) 90%
  );
}
#nav-bar-links {
  display: flex;
  align-items: center;
  justify-content: center;
}
#nav-bar-links li {
  list-style: none;
  padding: 0 10px;
  position: relative;
}
#nav-bar-links li a {
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  color: #fff;
  transition: 0.3s ease-in-out;
}
#nav-bar-links li a:hover,
#nav-bar-links li a.active {
  color: rgb(201, 149, 29);
}
#nav-bar-links li a:hover::after,
#nav-bar-links li a.active::after {
  content: "";
  width: 60%;
  height: 2px;
  background: rgb(201, 149, 29);
  position: absolute;
  bottom: -4px;
  left: 20px;
}
.back-hr {
  background: red;
}
.back-hr1 {
  background: green;
}

.footer {
  padding: 20px 0;
  font-size: 14px;
  transition: all 0.3s;
  border-top: 1px solid #cddfff;
  background: #033ad2;
  color: white;
}
.no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 769px) {
  .error-cover {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: -webkit-center;
  }
  h5 span {
    display: block;
  }

  .carousel-div {
    position: relative;
    width: 100vw;
    height: 40vw;
  }
  .carousel-div img {
    width: 100vw !important;
  }
  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #033ad2;
    padding: 10px 80px !important;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 5%);
    transition: 0.3s ease-in-out;
    width: 100vw;
  }
  .nav-bar h5 {
    font-size: 18px;
  }
  #nav-bar-links h4 {
    font-size: 18px;
    color: #fff;
  }
  #nav-bar-links a {
    font-size: 16px;
    color: #fff;
    text-decoration: underline;
  }
  #nav-bar-links a span {
    font-size: 16px;
    color: rgb(201, 149, 29);
  }
  .advert-margin {
    padding: 0px 60px;
  }
  #nav-bar-links {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #nav-bar-links li {
    list-style: none;
    padding: 0 20px;
    position: relative;
  }
  #nav-bar-links li a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    transition: 0.3s ease-in-out;
  }
  .verify-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }
  .container-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.5);
    padding: 0px;
    width: 600px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .pad-left {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .illustration {
    position: relative;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(1, 41, 112),
      rgba(37, 118, 184, 0.75)
    );
    text-align: -webkit-center;
    padding-top: 70px;
  }
  .illus-img img {
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 150px;
  }
  .illust-img img {
    position: absolute;
    bottom: 0;
    right: 0px;
  }
  .container-wrapper {
    background: #fff;
    background-size: cover;
    background-position: center;
    margin: 0 !important;
    padding: 20px;
  }

  i.email {
    right: 20px;
    top: 13px;
    color: rgb(1, 49, 89, 0.5);
  }

  input {
    height: 40px;
    text-align: center;
    background: none;
    border: none;
    color: #013159;
    font-size: 12px;
  }

  input.email {
    border: 2px solid #013159;
    border-radius: 50px;
  }
  i.password {
    left: 20px;
    top: 13px;
    color: rgb(1, 49, 89, 0.5);
  }

  input.password {
    border: 2px solid #013159;
    border-radius: 50px;
  }
  button.submit {
    background: none;
    border: none;
    border: 2px solid #013159;
    color: #013159;
    font-size: 12px;
    border-radius: 50px;
    width: 170px;
    padding: 5px 0px;
  }
  button.submit:hover {
    color: white;
    background: #013159;
  }
  input[type="checkbox"] {
    border: 2px solid #013159;
    background: none;
  }

  label {
    color: #013159;
    font-size: 12px;
  }

  button.action {
    font-size: 12px;
    color: #013159;
    background: none;
    border: 2px solid #013159;
    border-radius: 20px;
    padding: 5px 0px;
  }
  button.action:hover {
    color: white;
    background: #013159;
  }

  h1 {
    color: #033ad2;
  }

  ::-webkit-input-placeholder {
    color: rgb(1, 49, 89, 0.5);
  }

  input:focus {
    outline: none;
  }
  .illust-text h4 {
    color: #fff;
    font-size: 50px;
  }
  .illust-text h4 span {
    color: rgb(201, 149, 29);
  }
  .table-height2 {
    margin: 60px auto;
    width: 80%;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.15);
  }
  .enroll-font {
    font-size: 18px;
  }
  .img-backbo {
    background: url("../../../../public/assets/images/backblue.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    position: absolute;
  }
  .table-height4 {
    margin: 60px auto;
    width: 100%;
    border-radius: 8%;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.15);
  }
  .slick-slide .sli-img {
    width: 60vw;
    height: 28vw;
    margin: 0px 30px 0px 0px !important;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 15%);
  }

  .table-height3 {
    width: 100%;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.15);
  }
  .line {
    width: 200px;
    height: 1px;
    background: #000;
  }
  .line2 {
    width: 300px;
    height: 1px;
    background: #000;
  }
  .line4 {
    width: 55vw;
    height: 1px;
    background: #000;
  }
  .line3 {
    width: 25vw;
    height: 1px;
    background: #000;
  }
  .marg-top {
    margin-top: 100px;
  }
  .line1 {
    margin-top: 60px;
    width: 400px;
    height: 1px;
    background: #000 !important;
  }
  .enroll-box {
    width: 200px;
    height: 30px;
    border: 1px solid #000;
  }
  .table-height {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    background: transparent;
    padding: 0 !important;
  }
  .formtype h4 {
    font-size: 16px;
  }
  .form-cover {
    border: 1px solid #033ad2;
  }
  .login-img {
    width: 200px;
  }
  .img-cover {
    margin: 20px auto;
    width: 280px;
    height: 300px;
    border: 1px solid #033ad2;
    overflow: hidden;
  }
  .main-cover {
    width: 300px;
    margin: 20px auto;
  }
  .img-cover img {
    width: 300px;
    height: 300px;
  }
  #pic-upload {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .head-cover {
    text-transform: uppercase;
  }
  .admiss {
    border: 1px solid #000;
    width: 200px;
    margin: 10px auto;
    padding: 10px 0px;
  }
  .offer-cover h5{
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
  }
  .offer-body h4{
    font-weight: 300 !important;
    font-size: 18px;
  }
  .offer-body h5{
    font-weight: 600 !important;
    font-size: 18px;

  }
  .wrapper1 {
    width: 200px;
    height: 200px;
    border: 1px solid #000;
    margin-top: -100px;
    overflow: hidden;
  }
  .wrapper1 img {
    width: 200px;
    height: 200px;
  }
  .body-content h4 {
    font-size: 1vw;
    font-weight: 700;
  }
  .body-content h4 span {
    font-weight: 100;
  }
  .img-position {
    width: 60px;
    height: 60px;
    margin: 2px auto;
  }
  .body-content h3 {
    font-size: 1.2vw;
    font-weight: 600;
    text-align: -webkit-center;
  }
  .div-content {
    margin-top: 10px;
    width: 100%;
    display: flex;
  }
  .div-content-1 {
    width: 66.66%;
  }
  .div-content-2 {
    width: 33.33%;
  }
  .div-content-3 {
    width: 50%;
  }
  .div-content-cover {
    padding: 10px;
    border: 1px solid #000;
  }
  .div-content-fit {
    width: 50%;
  }
  .div-content-fit span {
    display: block;
    font-size: 1vw;
    color: #000;
    font-weight: 500;
  }
  .div-content-fit p {
    font-size: 1vw;
    color: #000;
    font-weight: 500;
  }
  .div-content-fit1 {
    width: 100%;
  }
  .div-content-fit1 span {
    display: block;
    font-size: 1vw;
    color: #000;
    font-weight: 500;
  }
  .div-content-fit1 p {
    font-size: 1vw;
    color: #000;
    font-weight: 500;
  }
  .space-line {
    width: 100%;
    height: 1px;
    background: #000000;
  }
  .d-width {
    width: fit-content;
  }
  .stu_id h1 {
    font-size: 2vw;
  }
  .stu_id1 h4 {
    font-weight: 700;
    font-size: 20px;
  }
}
